<template>
  <div class="page403">
    <div>
      <!-- <h3 class="title">缙云数字经济综合应用</h3> -->
      <div class="notice">
        <img src="@/assets/403_icon.png" alt="" mode="widthfix" />
        <div class="noticemsg title">请用浙政钉2.0打开</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page403 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  flex-direction: column;
  h3 {
    font-size: 36px;
    margin-bottom: 42px;
  }
  .noticemsg {
    font-size: 30px;
  }
  .title {
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #59aaf5;
  }
  .notice {
    width: 560px;
    height: 400px;
    background: #ffffff;
    box-shadow: 0px 5px 17px 1px rgba(91, 95, 162, 0.1);
    border-radius: 10px;
    img {
      width: 172px;
      margin-top: 77px;
      margin-bottom: 37px;
    }
  }
}
@media screen and (max-width: 560px) {
  .page403 {
    h3 {
      font-size: 22px;
      margin-bottom: 40px;
    }
    .noticemsg {
      font-size: 18px;
    }
    .notice {
      width: 60vw;
      height: 50vw;
      img {
        width: 40%;
        margin-top: 10%;
        margin-bottom: 10%;
      }
    }
  }
}
</style>
